<template>
  <bulma-table v-if="activeItems.length > 0 || isEditable"
    class="table is-narrow fixed-layout"
    :columns="columns"
    :total-rows="totalRows"
    :show-footer="true"
    :sort-column="filter.sortColumn"
    :sort-order="filter.sortOrder"
    :draggable="false"
    :show-pagination="false"
    :is-title-checkable="isEditable && activeItems.length > 0"
    title-checkbox-location="front"
    :is-title-checked="selectAllChecked"
    @title-checkbox-changed="selectAllItems"
    @pageChanged="onPageChange"
    @sort="sort">
    <template slot="colgroup">
      <colgroup>
        <col v-if="isEditable && activeItems.length > 0"
          class="width-3">
        <col :class="`width-${columns[0].width}`">
        <col :class="`width-${columns[1].width}`">
        <col :class="`width-${columns[2].width}`">
        <col :class="`width-${columns[3].width}`">
        <col :class="`width-${columns[4].width}`">
        <col :class="`width-${columns[5].width}`">
        <col v-if="isEditable"
          :class="`width-${columns[6].width}`">
      </colgroup>
    </template>
    <template slot="header">
      <tr class="is-size-5 gray-row">
        <th class="has-text-info has-text-weight-normal"
          :colspan="itemDescColspan">
          {{ titleItemType }}
        </th>
        <th class=" has-vertical-middle has-text-weight-normal"
          :colspan="quotedColspan"> Quoted </th>
        <th class="left-right-items has-vertical-middle has-text-weight-normal"
          :colspan="authorisedColspan">
          <div class="left-item mr-4">
            <span>Authorised</span>
          </div>
        </th>
        <th v-if="!editAuthorisedMode" />
      </tr>
    </template>
    <tr class="height-3"
      v-for="(item, index) in activeItems"
      :class="item.isQuoteItemDeleted ? 'strike-through-line' : ''"
      :key="item.quoteItemId">
      <td v-if="isEditable"
        class="has-vertical-middle has-text-centered is-content-width no-strike-through-line">
        <div class="pretty p-icon p-curve p-smooth m-0 p-bigger">
          <input v-model="item.isSelected"
            @change="selectChanged(index, item)"
            type="checkbox">
          <div class="state p-info">
            <i class="icon mdi mdi-check" />
            <label />
          </div>
        </div>
      </td>
      <td class="has-vertical-middle itemdesc-input">
        <div>
          <span v-if="!editQuotedMode && !editAuthorisedMode"
            class="mr-1">
            <span class="tooltip"
              data-tooltip="New Item">
              <i v-if="isItemNew(item)"
                class="has-text-success mdi-20px mdi mdi-arrow-right-box" />
            </span>
            <span class="tooltip"
              data-tooltip="Modified Item">
              <i v-if="isItemValueIncreased(item)"
                class="has-text-warning mdi-20px mdi mdi-arrow-up-box" />
            </span>
            <span class="tooltip"
              data-tooltip="Modified Item">
              <i v-if="isItemValueDecreased(item)"
                class="has-text-warning mdi-20px mdi mdi-arrow-down-box" />
            </span>
          </span>
          <input v-if="item.isAdded"
            class="input has-vertical-middle has-vertical-middle"
            :class="[$v.activeItems.$each[index].itemDesc.required ? '' : 'is-danger']"
            placeholder="Item Description"
            v-model="item.itemDesc"
            @change="updateAssessmentItem(index, item)">
          <span v-else>{{ item.itemDesc }}</span>
          <div v-if="item.itemComment"
            class="help is-primary height-1">
            <span class="has-text-weight-bold">Comment: </span>
            <span>{{ item.itemComment }}</span>
          </div>
        </div>
      </td>
      <td class="has-vertical-middle has-text-centered">
        <div v-if="isEditable && !item.isQuoteItemDeleted"
          class="pretty p-icon p-curve p-smooth m-0 p-bigger">
          <input v-model="item.reportOnly"
            @change="updateAssessmentItem(index, item)"
            :disabled="!canMakeReportOnly(item)"
            type="checkbox">
          <div class="state p-info">
            <i class="icon mdi mdi-check" />
            <label />
          </div>
        </div>
        <span v-else
          class="icon has-text-info">
          <i class="mdi mdi-24px"
            :class="[ item.reportOnly ? 'mdi-check' : '' ]" />
        </span>
      </td>
      <td class="has-vertical-middle has-text-centered is-content-width">
        <div v-if="item.submittedItem"
          :class="reportOnlyColorClass(item.submittedItem)">
          <span>{{ item.vendorName }}</span>
        </div>
        <span v-else>-</span>
      </td>
      <td class="has-vertical-middle has-text-right money-input">
        <div v-if="item.submittedItem">
          <vue-numeric v-if="editQuotedMode && !item.isQuoteItemDeleted"
            class="input has-text-right has-vertical-middle"
            :class="reportOnlyColorClass(item.submittedItem)"
            v-model.number="item.submittedItem.value"
            :minus="true"
            :precision="2"
            @input="updateAssessmentItem(index, item)" />
          <div v-else
            class="relative-container">
            <span>{{ item.submittedItem.reportOnly ? 0 : item.submittedItem.value | formatNumber($userInfo.locale) }}</span>
            <span v-if="item.submittedItem.reportOnly"
              class="reported-value is-size-7">{{ '(' + $filters.formatNumber(item.submittedItem.value, $userInfo.locale) + ')' }}</span>
          </div>
        </div>
        <span v-else>-</span>
      </td>

      <td class="has-vertical-middle has-text-centered is-content-width"
        :class="[item.isQuoteItemDeleted ? '' : tableCellColorClass(item.authorisedItem, item.submittedItem)]">
        <div v-if="item.authorisedItem"
          :class="reportOnlyColorClass(item.authorisedItem)">
          <span>{{ item.vendorName }}</span>
        </div>
        <span v-else>-</span>
      </td>
      <td class="has-vertical-middle has-text-right money-input"
        :class="[item.isQuoteItemDeleted ? '' : tableCellColorClass(item.authorisedItem, item.submittedItem)]">
        <div v-if="item.authorisedItem">
          <vue-numeric v-if="editAuthorisedMode && !item.isQuoteItemDeleted"
            class="input has-text-right has-vertical-middle"
            :class="reportOnlyColorClass(item.authorisedItem)"
            v-model.number="item.authorisedItem.value"
            :minus="true"
            :precision="2"
            @input="updateAssessmentItem(index, item)" />
          <div v-else
            class="relative-container">
            <span>{{ item.authorisedItem.reportOnly ? 0 : item.authorisedItem.value | formatNumber($userInfo.locale) }}</span>
            <span v-if="item.authorisedItem.reportOnly"
              class="reported-value is-size-7">{{ '(' + $filters.formatNumber(item.authorisedItem.value, $userInfo.locale) + ')' }}</span>
          </div>
        </div>
        <span v-else>-</span>
      </td>

      <td v-if="isEditable"
        class="has-vertical-middle has-text-centered is-content-width no-strike-through-line">
        <div class="icon-container">
          <div v-if="editAuthorisedMode || editQuotedMode">
            <button v-if="!item.isQuoteItemDeleted && item.isDeleted !== true && item.deleted !== true"
              class="button is-danger is-small is-inverted tooltip has-vertical-middle"
              @click="deleteItemAuthorise(item, index)"
              :disabled="value.readOnly || item.isQuoteItemDeleted"
              data-tooltip="Delete">
              <span class="icon is-small">
                <i class="mdi mdi-delete mdi-24px" />
              </span>
            </button>
            <button v-if="item.isQuoteItemDeleted"
              class="button is-info is-small is-inverted has-vertical-middle"
              @click="undoDeleteItem(item, index)">
              <span class="icon is-small">
                <i class="mdi mdi-undo mdi-24px" />
              </span></button>
          </div>
        </div>
      </td>
    </tr>
    <template v-if="activeItems.length === 0"
      slot="empty">
      <section class="mt-3 mb-3">
        <div class="content has-text-grey has-text-centered">
          <span icon="icon is-large">
            <i class="mdi mdi-48px mdi-emoticon-sad" />
          </span>
          <p>No Item available in this category</p>
        </div>
      </section>
    </template>
    <template slot="footer">
      <tr>
        <td class="has-vertical-middle"
          :colspan="itemDescColspan">
          <div v-if="isEditable"
            class="left-item">
            <button class="button is-info is-outlined has-vertical-middle"
              data-tooltip="add item"
              @click="addItem()">
              <div class="is-size-7">
                <span class="icon">
                  <i class="mdi mdi-plus" />
                </span>
                <span>Add Item</span>
              </div>
            </button>
          </div>
        </td>
        <td class="has-text-right has-vertical-middle"
          :colspan="quotedColspan">
          <div>
            <span class="mr-2">Quoted {{ titleItemType }} Totals:</span>
            <span class="has-text-weight-bold">${{ quoteTotal | formatNumber($userInfo.locale) }}</span>
          </div>
        </td>
        <td class="has-text-right has-vertical-middle"
          :colspan="editAuthorisedMode ? authorisedColspan - 1 : authorisedColspan">
          <div>
            <span class="mr-2">Authorised {{ titleItemType }} Totals:</span>
            <span class="has-text-weight-bold"
              :class="totalAuthorisedColorClass">${{ authorisedTotal | formatNumber($userInfo.locale) }}</span>
          </div>
        </td>
        <td />
      </tr>
    </template>
  </bulma-table>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { PaintGroupTypes } from '@/enums'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import { AuthorisedSubletColumns } from '../columns'
import BulmaTable from '@/components/BulmaTable'
import _cloneDeep from 'lodash/cloneDeep'
import { QuoteAssessmentMixin, QuoteOpgMixin } from '../mixins'
import VueNumeric from '@/components/VueNumeric'
import { ItemCategoryCodeTypes } from '../../../enums'
import QuoteFiltersMixins from '../QuoteFiltersMixins'

// import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'

export default {
  name: 'AuthoriseSublet',
  components: {
    BulmaTable,
    VueNumeric
  },
  mixins: [NumberFiltersMixin, QuoteOpgMixin, QuoteAssessmentMixin, QuoteFiltersMixins],
  props: {
    value: null,
    assessmentItems: null,
    itemType: {
      type: String,
      default: ''
    },
    editQuotedMode: {
      type: Boolean,
      default: false
    },
    editAuthorisedMode: {
      type: Boolean,
      default: false
    },
    isDraft: {
      type: Boolean,
      default: false
    },
    selectAll: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      innerValue: this.value,
      innerAssessmentItems: [],
      filter: {
        sortColumn: AuthorisedSubletColumns[0].name,
        sortOrder: AuthorisedSubletColumns[0].defaultOrder
      },
      quoteTotal: 0,
      authorisedTotal: 0
    }
  },
  validations: {
    activeItems: {
      $each: {
        itemDesc: {
          required
        }
      }
    }
  },
  computed: {
    columns() {
      let c = _cloneDeep(AuthorisedSubletColumns)
      if (!this.isEditable) {
        c.pop() //Remove Action column
        c[0].width += 3 //Description
      }
      if (this.isEditable && this.activeItems.length > 0) {
        c[0].width -= 3 //Description
      }
      return c
    },
    paintGroupTypes() {
      return PaintGroupTypes
    },
    isEditable() {
      return this.editQuotedMode || this.editAuthorisedMode
    },
    itemDescColspan() {
      let colspan = this.columns.filter((c) => c.category === 'Desc').length
      if (this.isEditable && this.activeItems.length > 0) {
        colspan++
      }
      return colspan
    },
    quotedColspan() {
      return this.columns.filter((c) => c.category === 'Quoted').length
    },
    authorisedColspan() {
      const span = this.columns.filter((c) => c.category === 'Authorised').length
      if (this.editAuthorisedMode) {
        return span + 1
      }
      return span
    },
    titleItemType() {
      const itemCategory = ItemCategoryCodeTypes.itemTypes.find((c) => c.code === this.itemType)
      if (itemCategory) {
        return itemCategory.displayName
      }
    },
    selectAllChecked() {
      if (this.activeItems.every((item) => item.isSelected)) {
        return true
      } else if (this.activeItems.some((item) => item.isSelected)) {
        return null
      } else {
        return false
      }
    },
    totalRows() {
      return this.activeItems.length
    },
    activeItems() {
      if (!this.innerAssessmentItems) {
        return []
      }
      return this.innerAssessmentItems.filter((item) => {
        return item.itemType === this.itemType && !(item.isAdded && (item.isDeleted === true || item.deleted === true))
      })
    },
    totalAuthorisedColorClass() {
      if (this.authorisedTotal > this.quoteTotal) {
        return 'has-text-success'
      } else if (this.authorisedTotal < this.quoteTotal) {
        return 'has-text-danger'
      } else {
        return ''
      }
    }
  },
  watch: {
    selectAll(newValue) {
      if (newValue === true) {
        this.selectAllItems(newValue)
      } else if (newValue === false) {
        this.selectAllItems(newValue)
      }
    },
    assessmentItems: {
      handler(newValue) {
        this.innerAssessmentItems = _cloneDeep(newValue)
      },
      deep: true
    },
    innerAssessmentItems: {
      handler(newValue) {
        this.updateTotals()
      },
      deep: true
    },
    selectAllChecked(newValue) {
      this.$emit('select-all-changed', newValue, this.itemType)
    }
  },
  created() {
    this.innerAssessmentItems = _cloneDeep(this.assessmentItems)
    this.filter.sortColumn = this.columns[0].name
    this.filter.sortOrder = this.columns[0].defaultOrder
  },
  mounted() {},
  methods: {
    onPageChange() {},
    updateTotal() {},
    sort() {},
    addItem() {
      const newItem = this.addBlankItemAuthorise()
      this.$emit('add-assessment-item', newItem)
    },
    updateAssessmentItem(index, item) {
      // calculate dollar value
      if (this.editAuthorisedMode) {
        item.authorisedItem.isQuoteItemDeleted = item.isQuoteItemDeleted
        item.authorisedItem.reportOnly = item.reportOnly
        item.authorisedItem.isSelected = item.isSelected
        item.authorisedItem.itemDesc = item.itemDesc
      }
      if (this.editQuotedMode) {
        item.submittedItem.isQuoteItemDeleted = item.isQuoteItemDeleted
        item.submittedItem.reportOnly = item.reportOnly
        item.submittedItem.isSelected = item.isSelected
        item.submittedItem.itemDesc = item.itemDesc
      }
      this.$emit('update-assessment-item', item)
    },
    updateTotals() {
      const quotedItems = this.activeItems.filter((i) => i.submittedItem).map((item) => item.submittedItem)
      const authorisedItems = this.activeItems.filter((i) => i.authorisedItem).map((item) => item.authorisedItem)
      if (quotedItems.length > 0) {
        this.quoteTotal = this.calculateAssessmentItemCategoryTotal(this.value.quotingMethod, quotedItems, this.itemType)
      } else {
        this.quoteTotal = null
      }
      if (authorisedItems.length > 0) {
        this.authorisedTotal = this.calculateAssessmentItemCategoryTotal(this.value.quotingMethod, authorisedItems, this.itemType)
      } else {
        this.authorisedTotal = null
      }
    },
    selectChanged(index, item) {
      this.updateAssessmentItem(index, item)
    },
    isItemNew(item) {
      const itemDetails = item.authorisedItem ?? item.submittedItem
      return itemDetails.value === itemDetails.valueDelta
    },
    isItemValueIncreased(item) {
      const itemDetails = item.authorisedItem ?? item.submittedItem
      return itemDetails.value !== itemDetails.valueDelta && itemDetails.valueDelta > 0
    },
    isItemValueDecreased(item) {
      const itemDetails = item.authorisedItem ?? item.submittedItem
      return itemDetails.value !== itemDetails.valueDelta && itemDetails.valueDelta < 0 && !itemDetails.isQuoteItemDeleted
    },
    canMakeReportOnly(item) {
      const origninalItem = this.value.sublets.find((i) => i.quoteItemId === item.quoteItemId)
      if (origninalItem) {
        if ((item.submittedItem?.hasAuthoriseHistory || item.authorisedItem?.hasAuthoriseHistory) && !origninalItem.reportOnly) {
          return false
        }
      }
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.item-type-tag {
  width: 52px;
}
.present-as-tags {
  display: inline-flex;
  .tag {
    font-size: 0.6rem !important;
  }
}
.col-desc {
  width: 30%;
}
.col-quoted {
  width: 30%;
}
.col-authorised {
  width: 30%;
}
</style>